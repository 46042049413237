import {isSessionValid} from '@digistaff/app';
import {createContext, useEffect, useState} from 'react';
import {app} from './DSApp';

export interface AuthContextInterface {
  valid: boolean;
}
export const AuthContext = createContext<AuthContextInterface>(null!);

export const AuthProvider = ({children}: {children: React.ReactNode}) => {
  const [valid, setValid] = useState<boolean>(false);

  useEffect(() => {
    const status: boolean = isSessionValid(app);
    setValid(status);
    if (!status && window.location.pathname !== '/auth') {
      window.location.href = `${window.location.origin}/auth`;
    }
  }, []);

  return (
    <AuthContext.Provider value={{valid}}>{children}</AuthContext.Provider>
  );
};
