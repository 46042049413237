/* eslint-disable @typescript-eslint/no-explicit-any */
import {Stack, Button, Typography} from '@mui/material';
import {useEffect} from 'react';

export interface ResultProps {
  status: any;
  onComplete: () => void;
}
export const Result = ({status, onComplete}: ResultProps) => {
  useEffect(() => {
    setTimeout(() => {
      onComplete();
    }, 15000);
  }, [onComplete]);

  return (
    <Stack
      sx={{marginTop: '50px', padding: '50px'}}
      spacing={5}
      textAlign="center"
    >
      {status.result.includes('success') ? (
        <>
          <Typography variant="h3" color="green">
            {status.message}
          </Typography>
          <Typography variant="h3" color="#3E82BF">
            {`${status.currentShift?.clocked_shift_posting?.name} (${new Date(
              status.currentShift?.shift_date
            ).toLocaleDateString()})`}
          </Typography>
          <Typography variant="h3" color="#3E82BF">
            {`Staff: ${status.staff.first_name} ${status.staff.last_name}`}
          </Typography>
          <Typography variant="h3" color="#3E82BF">
            {`Logged: ${new Date(
              status.timestamp
            ).toLocaleDateString()} ${new Date(
              status.timestamp
            ).toLocaleTimeString()}`}
          </Typography>
          <Typography variant="h3" color="#3E82BF">
            Redirecting to the main view in 15 seconds...
          </Typography>
        </>
      ) : (
        <Typography variant="h3" color="red">
          {status.message}
        </Typography>
      )}
      <Button onClick={onComplete} variant="contained" sx={{padding: '40px'}}>
        <Typography variant="h3" color="#3E82BF">
          Press here to restart
        </Typography>
      </Button>
    </Stack>
  );
};
