import {
  init,
  signInWithEmailAndPassword,
  googleLogin,
  setTenantKey,
} from '@digistaff/app';

const app = init({
  ClientId: process.env.COGNITO_CLIENT_ID as string,
  environment: process.env.CLOUD_ENVIRONMENT as string,
});

export {app, signInWithEmailAndPassword, googleLogin, setTenantKey};
