/* eslint-disable @typescript-eslint/no-unused-vars */
import {Box, AppBar} from '@mui/material';
import {NewDigistaffLogoFullWhite} from '../Image/Image';

export const AuthViewHeader = () => {
  return (
    <AppBar position="sticky" sx={{display: 'flex', alignItems: 'center'}}>
      <Box
        component="img"
        sx={{width: '250px', cursor: 'pointer'}}
        alt="Digistaff Logo"
        src={NewDigistaffLogoFullWhite}
        display="flex"
        alignItems="center"
      />
    </AppBar>
  );
};
