/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {createRoot} from 'react-dom/client';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';

import 'moment-timezone';

import {appTheme} from './Theme';
import {CssBaseline, ThemeProvider} from '@mui/material';

import '../assets/scss/app.scss';
import {AuthProvider} from './providers/AuthProvider';
import {AuthView} from './Views/AuthView/AuthView';
import {MainView} from './Views/MainView/MainView';
import {RootView} from './Views/RootView/RootView';
import RouteErrorView from './Views/RouteErrorView/RouteErrorView';
import {Provider} from '@rollbar/react';

const container = document.getElementById('App') as Element;

const reactRoot = createRoot(container, {
  onRecoverableError: error => {
    console.log(`onRecoverableError: ${error}`);
  },
});

const browserRouter = createBrowserRouter([
  {
    path: '/',
    element: <RootView />,
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/main',
    element: <MainView />,
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/auth',
    element: <AuthView />,
    children: [],
    errorElement: <RouteErrorView />,
  },
]);

const rollbarConfig = {
  accessToken: process.env.ROLLBAR_KIOSK_KEY,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.CLOUD_ENVIRONMENT,
  autoInstrument: false,
  payload: {
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true,
      },
    },
  },
};

const RootContainer = () => {
  return (
    <React.StrictMode>
      <Provider config={rollbarConfig}>
        <ThemeProvider theme={appTheme}>
          <CssBaseline />
          <AuthProvider>
            <RouterProvider router={browserRouter} />
          </AuthProvider>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

reactRoot.render(<RootContainer />);
