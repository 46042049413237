import {Box, Button, Stack, Typography} from '@mui/material';
import {AuthViewHeader} from '../../common/AuthViewHeader/AuthViewHeader';
import OtpInput from 'react-otp-input';
import {useCallback, useState} from 'react';
import {getKioskCode} from '@digistaff/business';
import {app} from '../../providers/DSApp';
import {setTenantKey, signInMachineUser} from '@digistaff/app';
import {ToastStruct} from '../../types/types';
import {Toast} from '../../common/Alerts/Toast';

export const AuthView = () => {
  const [otp, setOtp] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>({
    severity: 'info',
    text: '',
    verticalPos: 'bottom',
    horizontalPos: 'center',
  });

  const handleSubmit = useCallback(async () => {
    if (otp.length === 8) {
      const result = await getKioskCode(app, otp);
      const tenant_key = result.data.getKioskCode.tenant_key;
      const token = result.data.getKioskCode.token;

      if (tenant_key && token) {
        localStorage.setItem('tenant_key', tenant_key);
        setTenantKey(app, tenant_key);
        signInMachineUser(app, token);
        setToastData({
          ...toastData,
          severity: 'success',
          text: 'Success, redirecting',
        });
        window.location.href = `${window.location.origin}/main`;
      } else {
        setToastData({
          ...toastData,
          severity: 'error',
          text: 'Invalid kiosk code, please try again',
        });
      }
    } else {
      setToastData({
        ...toastData,
        severity: 'warning',
        text: 'Please enter the kiosk code',
      });
    }
    setShowToast(true);
  }, [otp, toastData]);

  return (
    <Box>
      <AuthViewHeader />
      <Stack
        spacing={5}
        sx={{
          marginTop: '150px',
        }}
      >
        <Typography align="center" variant="h2">
          Enter Kiosk Code
        </Typography>
        <Box>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={8}
            renderSeparator={<span>-</span>}
            renderInput={props => <input {...props} />}
            shouldAutoFocus
            inputStyle={{
              width: '10%',
              height: '3.5rem',
              margin: '0 1rem',
              border: '1px solid rgba(0, 0, 0, 0.3)',
              fontSize: '2rem',
              borderRadius: '4px',
              color: '#3E82BF',
            }}
          />
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Button
            variant="contained"
            sx={{
              width: '300px',
              height: '75px',
              backgroundColor: '#3E82BF',
              color: 'white',
            }}
            size="large"
            onClick={handleSubmit}
          >
            <Typography variant="h4">Submit</Typography>
          </Button>
        </Box>
      </Stack>
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        severity={toastData.severity}
        text={toastData.text}
        verticalPos={toastData.verticalPos}
        horizontalPos={toastData.horizontalPos}
      />
    </Box>
  );
};
