/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import QrReader from 'react-qr-reader';

export interface QrProps {
  onComplete: (data: string) => void;
  setStage: (stage: string) => void;
  captureEnabled: boolean;
}
export const QrScanner = ({onComplete, setStage, captureEnabled}: QrProps) => {
  const [scanned, setScanned] = useState(false);
  const handleScan = (data: any) => {
    if (data) {
      setScanned(true);
      onComplete(data);
    }
  };

  const handleError = (err: any) => {
    console.log(err);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!scanned && captureEnabled) {
        setStage('capture');
      }
    }, 40000);

    return () => clearTimeout(timer);
  }, [scanned, setStage, captureEnabled]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '90vh',
        width: '100vw',
        overflow: 'hidden',
        padding: '1vh',
      }}
    >
      <Typography
        variant="h3"
        component="h2"
        fontWeight="fontWeightBold"
        textAlign="center"
        color="#3E82BF"
        marginY="25px"
      >
        Scan Your QR Code
      </Typography>
      <Box
        sx={{
          width: '100vw',
          height: {sm: '200px', md: '300px', lg: '400px'},
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <QrReader
          delay={2000}
          onError={handleError}
          onScan={handleScan}
          facingMode="user"
          style={{width: '50%'}}
        />
      </Box>
    </Box>
  );
};
