import {isSessionValid} from '@digistaff/app';
import {Box, CircularProgress} from '@mui/material';
import {useEffect} from 'react';
import {app} from '../../providers/DSApp';

export const RootView = () => {
  useEffect(() => {
    const valid = isSessionValid(app);
    if (valid) {
      window.location.href = `${window.location.origin}/main`;
    } else {
      window.location.href = `${window.location.origin}/auth`;
    }
  }, []);

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    </>
  );
};
