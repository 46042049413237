/* eslint-disable @typescript-eslint/no-explicit-any */
import {PanoramaFishEyeOutlined} from '@mui/icons-material';
import {Box, Button, Typography} from '@mui/material';
import {useRef} from 'react';
import Webcam from 'react-webcam';

export interface CaptureProps {
  onComplete: (data: string) => void;
}
export const CapturePhoto = ({onComplete}: any) => {
  const webcamRef = useRef<any>(null);
  const capture = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    onComplete(imageSrc);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100vw',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          position: 'relative',
        }}
      >
        <PanoramaFishEyeOutlined
          sx={{
            fontSize: {sm: '200px', md: '400px'},
            color: '#3E82BF',
            position: 'absolute',
          }}
        />
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width="50%"
          videoConstraints={{
            width: 1280,
            height: 720,
            facingMode: 'user',
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
          padding: '1vh',
        }}
      >
        <Typography
          variant="h6"
          component="h2"
          fontWeight="fontWeightBold"
          textAlign="center"
          color="#3E82BF"
          paddingX="40px"
        >
          To start check in/out process, take a picture while your face is
          within the circle
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#3E82BF',
            color: 'white',
            boxShadow: 3,
            alignSelf: 'center',
            mt: 2,
          }}
          onClick={capture}
        >
          <Typography variant="h4">Press Here To Take Photo</Typography>
        </Button>
      </Box>
    </Box>
  );
};
